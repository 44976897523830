<template>
    <div>
    <VideoInBrowser
        @waitBeforeLeaving="handleWaitBeforeLeaving"
        :leaving="leaving"
    />
    </div>
</template>
<script>
import VideoInBrowser from "../../components/video/VideoInBrowser";
export default {
    components: {VideoInBrowser},
    data() {
        return {
            leaving: false,
            wait: false,
        }
    },
    methods: {
        handleWaitBeforeLeaving(wait) {
            this.wait = wait;
        }
    },
    beforeRouteLeave(to, from, next) { // Without this, the chime sdk doesn't have time to perform the necessary functions to unbind the video
        this.leaving = true;
        if (this.wait) {
            setTimeout(() => {
                next();
            }, 300);
        } else {
            next();
        }
    }
}
</script>